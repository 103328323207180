<template>
    <div
        style="
            width: 100%;
            height: 600px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        "
    >
        <div class="">
            <el-card
                class="box-card"
                shadow="always"
                :body-style="{
                    height: '240px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-evenly',
                }"
            >
                <div class="title">
                    {{ "Hi，" + cname + "邀请您加入" }}
                </div>
                <el-button
                    type="primary"
                    style="margin-top: 15px"
                    :disabled="disabled"
                    @click="agreeInvite"
                >
                    同意加入
                </el-button>
            </el-card>
        </div>
        <switchId-dialog
            v-if="switchIdDialogVisible"
            ref="SwitchIdDialog"
        ></switchId-dialog>
    </div>
</template>

<script>
import { agreeCompanyInvite, agreeCompanyInviteAndIsSelf } from "@/api";
import SwitchIdDialog from "@/components/register/SwitchIdDialog";
import { apiCallback } from "../../../utils";
export default {
    data() {
        return {
            cname: "",
            disabled: true,
            switchIdDialogVisible: false,
        };
    },
    components: { SwitchIdDialog },
    mounted() {
        if (this.$route.query.key && this.$route.query.channelCode) {
            this.getInviteInfo();
        } else {
            this.$router.push("/404");
        }
    },
    methods: {
        //验证身份
        async getInviteInfo() {
            var that = this;
            agreeCompanyInviteAndIsSelf({
                key: this.$route.query.key,
                channelCode: this.$route.query.channelCode,
            })
                .then((res) => {
                    const data = apiCallback(res);
                    if (data.user.id !== this.$store.state.user.userId) {
                        that.$message.error("当前用户与邀请用户不符");
                    } else {
                        that.cname = data.company.cname;
                        that.disabled = false;
                    }
                })
                .catch((err) => console.error(err));
        },
        //同意邀请
        async agreeInvite() {
            var that = this;
            var data = {};
            data.key = this.$route.query.key;
            data.channelCode = this.$route.query.channelCode;
            agreeCompanyInvite(data)
                .then(async (res) => {
                    that.disabled = true;
                    that.isSwitchId();
                })
                .catch((err) => console.error(err));
        },
        //切换身份
        isSwitchId() {
            this.$confirm("加入成功，是否切换身份", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "success",
            })
                .then(() => {
                    this.showSwitchIdDialog();
                })
                .catch(() => {
                    //点击取消执行
                    this.$message({
                        type: "info",
                        message: "已取消",
                    });
                });
        },
        showSwitchIdDialog() {
            this.switchIdDialogVisible = true;
            this.$nextTick(() => {
                this.$refs.SwitchIdDialog.init();
            });
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="stylus" scoped>
.err404 {
  width: 820px;
  height: 500px;
  background: url('/assets/img/404.png') no-repeat 0 0;
}

.title {
  font-size: 24px;
}

.box-card {
  width: 500px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
